import { SET_CURRENT_USER } from 'store/actions/types'
import { GlobalManager } from 'services'

const initialState = {
  isAuthenticated: false,
  user: {}
}

export default function (state = initialState, action) {
  if (action.type === SET_CURRENT_USER) {
    return {
      ...state,
      isAuthenticated: !GlobalManager.isEmpty(action.payload),
      user: action.payload
    }
  }
  return state
}
