import React, { Fragment } from 'react'
import moment from 'moment'
import { AppBar, Toolbar, Container, Typography, ButtonBase, Divider, IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { getCurrentLanguage, localize } from '../../services/index'
import { Facebook, LinkedIn } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  bottomBar: {
    top: 'auto',
    bottom: 0,
    fontFamily: '\'DM Sans\', serif',
    minHeight: 296,
    backgroundColor: 'transparent'
  },
  bottomBarDivider: {
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  menuButton: {
    minWidth: '60px',
    padding: 16,
    fontWeight: 'bold',
    whiteSpace: 'nowrap'
  }
}))

const PublicFooter = props => {
  const { externalLinks } = props
  const classes = useStyles()
  const links = { ...externalLinks[getCurrentLanguage()] }

  return (
    <Fragment>
      <div className="bottomBar">
        <AppBar className={clsx(classes.bottomBar)} position='static'>
          <Toolbar className={classes.topBar} justify='space-between'>
            <Container className={clsx('d-flex flex-column')}>
              <div className={clsx('d-flex flex-wrap w-full justify-content-center dark-text m-3 font-size-16')}>
                <ButtonBase className={classes.menuButton} href={links.contact || localize('externalLinks.contact')}
                  target='_blank'>{localize('contact')}</ButtonBase>
                <ButtonBase className={classes.menuButton} href={links.faq || localize('externalLinks.faq')}
                  target='_blank'>{localize('faq')}</ButtonBase>
                <ButtonBase className={classes.menuButton} href={links.mediaKit || localize('externalLinks.mediaKit')}
                  target='_blank'>{localize('mediaKit')}</ButtonBase>
                <ButtonBase className={classes.menuButton} href={links.legal || localize('externalLinks.legal')}
                  target='_blank'>{localize('legal')}</ButtonBase>
                <ButtonBase className={classes.menuButton} href={links.support || localize('externalLinks.support')}
                  target='_blank'>{localize('support')}</ButtonBase>
              </div>
              <div
                className={clsx('d-flex w-full justify-content-center no-wrap')}>
                <IconButton href={links.facebook || localize('externalLinks.facebook')} target='_blank' color='secondary'>
                  <Facebook fontSize='large'/>
                </IconButton>
                <IconButton href={links.linkedin || localize('externalLinks.linkedin')} target='_blank' color='secondary'>
                  <LinkedIn fontSize='large'/>
                </IconButton>
              </div>
              <Divider className={clsx(classes.bottomBarDivider, 'my-4')}/>
              <div className={clsx('d-flex w-full justify-content-center text-center py-4 mb-4 light-text')}>
                <Typography>{localize('copyright', moment().year())}</Typography>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    </Fragment>
  )
}

export default PublicFooter
