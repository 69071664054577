import React, { Fragment } from 'react'

import PagesError505Content from '../../theme-components/PagesError505/PagesError505Content/index'

export default function PagesError505 () {
  return (
    <Fragment>
      <PagesError505Content />
    </Fragment>
  )
}
