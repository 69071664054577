export var primary = "#2F80ED"
export var primaryLight = "#495368"
export var primaryDark = "#09142a"
export var secondary = "#2F80ED"
export var inheritDefault1 = "#fefefe"
export var inheritDefault2 = "#f8f9ff"
export var second = "#070919"
export var indigo = "#7420ff"
export var purple = "#793de6"
export var pink = "#fc26a4"
export var red = "#f83245"
export var orange = "#f4772e"
export var yellow = "#ffc926"
export var green = "#1bc943"
export var teal = "#18e1a5"
export var cyan = "#27dcf3"
export default {"primary":"#2F80ED","primaryLight":"#495368","primaryDark":"#09142a","secondary":"#2F80ED","inheritDefault1":"#fefefe","inheritDefault2":"#f8f9ff","second":"#070919","indigo":"#7420ff","purple":"#793de6","pink":"#fc26a4","red":"#f83245","orange":"#f4772e","yellow":"#ffc926","green":"#1bc943","teal":"#18e1a5","cyan":"#27dcf3"}
