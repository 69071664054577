import BarChartIcon from '@material-ui/icons/BarChart'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ChatIcon from '@material-ui/icons/ChatOutlined'
import CodeIcon from '@material-ui/icons/Code'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import FolderIcon from '@material-ui/icons/FolderOutlined'
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone'
import GradeTwoTone from '@material-ui/icons/GradeTwoTone'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined'
import MailIcon from '@material-ui/icons/MailOutlined'
import PresentToAllIcon from '@material-ui/icons/PresentToAll'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import PersonIcon from '@material-ui/icons/PersonOutlined'
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import ViewModuleIcon from '@material-ui/icons/ViewModule'
import AssignmentIcon from '@material-ui/icons/Assignment'
import { localize } from '../../services'

const iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  AssignmentIcon: AssignmentIcon
}

export default [
  {
    label: 'Navigation menu',
    content: JSON.parse(
      `[
  {
    "label": "Dashboards",
    "icon": "DashboardTwoToneIcon",
    "content": [
      {
        "label": "Default",
        "description": "This is a dashboard page example built using this template.",
        "to": "/home"
      }
    ]
  },
    {
    "label": "` + localize('menu.employees') + `",
    "icon": "PersonIcon",
    "content": [
      {
        "label": "Administrator",
        "description": "",
        "to": "/employer/list/administrator"
      },    
      {
        "label": "Advisor",
        "description": "",
        "to": "/employer/list/advisor"
      },
      {
        "label": "Trader",
        "description": "",
        "to": "/employer/list/trader"
      },
      {
        "label": "Accountant",
        "description": "",
        "to": "/employer/list/accountant"
      }
    ]
  },
  {
    "label": "Transactions",
    "icon": "AssignmentIcon",
    "content": [
      {
        "label": "Upload XML",
        "description": "",
        "to": "/payment"
      },
      {
        "label": "Invalid",
        "description": "",
        "to": "/transaction/list/invalid"
      },    
      {
        "label": "Pending",
        "description": "",
        "to": "/transaction/list/pending"
      },
      {
        "label": "All",
        "description": "",
        "to": "/transaction/list/all"
      }]
  },
  { 
  "label": "` + localize('menu.customers') + `",
   "icon": "PersonIcon",
   "to": "/customer/list"
  },
  { 
  "label": "` + localize('menu.products') + `",
   "icon": "AssignmentIcon",
   "to": "/product/list"
  },
   { 
  "label": "` + localize('menu.userGroups') + `",
   "icon": "PersonIcon",
   "to": "/user-group/list"
  }
]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value]
        } else {
          return value
        }
      }
    )
  }
]
