import config from '../config/config'

const { USER_BACKEND } = config

export const routes = {
  USER_LOGIN: USER_BACKEND + '/public/login',
  NEW_SAVING_PLAN_CONFIRMED: USER_BACKEND + '/public/data-confirm/account/new-saving-plan/',
  CLOSE_SAVING_PLAN_CONFIRMED: USER_BACKEND + '/public/data-confirm/account/close-saving-plan/',
  USER_AND_ACCOUNT_DATA: USER_BACKEND + '/public/data-confirm/account/data',
  PURCHASE_CONFIRMED_BY_CUSTOMER: USER_BACKEND + '/public/data-confirm/purchase/customer-confirmation/',
  CREATION_ONE_TIME_ORDER_CONFIRMED_BY_CUSTOMER: USER_BACKEND + '/public/data-confirm/one-time-order/customer-confirmation/',
  PURCHASE_DATA: USER_BACKEND + '/public/data-confirm/purchase/data',
  ONE_TIME_ORDER_DATA: USER_BACKEND + '/public/data-confirm/one-time-order/data',
  NOTIFICATION_SETTINGS_BY_ENCRYPTED_ID: USER_BACKEND + '/public/data-confirm/account/notification-settings/data',
  UPDATE_NOTIFICATION_SETTINGS: USER_BACKEND + '/public/data-confirm/account/notification-settings/update/',
  REMOVE_TOKEN: USER_BACKEND + '/public/token/token/remove',
  USER_AND_ACCOUNT_DATA_FOR_REVISION: USER_BACKEND + '/public/data-confirm/account/revision-data',
  REVISION_CONFIRMATION: USER_BACKEND + '/public/data-confirm/revision/confirm-revision',
  SETUP_NEW_PASSWORD: USER_BACKEND + '/public/setup-new-password',
  VERIFY_TOKEN: USER_BACKEND + '/public/token/verify-token',
  GENERATE_2FA_CODE: USER_BACKEND + '/public/send-request-for-2fa-key/',
  VERIFY_2FA_CODE: USER_BACKEND + '/public/verify-2fa-key',
  RESET_PASSWORD_REQUEST: USER_BACKEND + '/public/reset-password-identification',
  GET_EXTERNAL_LINKS: USER_BACKEND + '/public/settings/externalLinks',
  REGISTER_NEW_CUSTOMER: USER_BACKEND + '/public/data-confirm/customer/register',
  RESEND_CONFIRMATION_EMAIL: USER_BACKEND + '/public/data-confirm/customer/confirmation/',
  CHANGE_TERMS_AND_NEWS: USER_BACKEND + '/public/data-confirm/account/terms-news/accept',
  CUSTOMER_DEACTIVATION_CONFIRMATION: USER_BACKEND + '/public/data-confirm/account/deactivation-confirm/',
  GET_SETTINGS_BY_KEY: USER_BACKEND + '/public/settings/',
  GET_SETTINGS: USER_BACKEND + '/public/settings'
}
