import axios from 'axios'
import config from 'config/config'
import store from 'config/configureStore'
import { localize } from '../'
import { httpCodes, responseAdvancedCodes } from 'constants/Constants'
import AuthenticationManager from 'store/actions'
import GlobalManager from '../GlobalManager'

const { BASE_URL } = config
const API_GATEWAY_ERROR_MESSAGE = 'No credentials found for given \'iss\''
const AxiosInstance = axios.create({
  baseURL: BASE_URL
})

AxiosInstance.interceptors.request.use(request => {
  return request
}, error => {
  return Promise.reject(error)
})

AxiosInstance.interceptors.response.use(request => {
  return request
}, error => {
  const { response } = error
  const { data } = response
  const httpCode = response.status
  let messageForShow = ''

  if (data.message === API_GATEWAY_ERROR_MESSAGE) {
    messageForShow = localize('userDoesntExist')
  } else if (httpCode === httpCodes.UNAUTHORIZED) {
    // 401
    store.dispatch(AuthenticationManager.logoutUser)
    messageForShow = localize('Error.tokenExpirationError')
  } else if (httpCode === httpCodes.FORBIDDEN) {
    // 403
    messageForShow = localize('Error.thisContentIsForbidden')
  } else if (httpCode >= httpCodes.INTERNAL_SERVER_ERROR) {
    // 500
    messageForShow = localize('Error.serverNotRespond')
  } else if (httpCode === httpCodes.NOT_FOUND) { // 404
    messageForShow = data.code === responseAdvancedCodes.USER_NOT_EXIST ? localize('Error.userWithEmailNotExist') : localize('Error.contentNotAvailable')
  } else { // 400-404
    if (data.code === responseAdvancedCodes.SEQUELIZE_VALIDATION_ERROR || data.code === responseAdvancedCodes.USER_DATA_VALIDATION) {
      let validationMessages = ''
      const validationData = data.data
      for (const field in validationData) {
        validationMessages += localize(validationData[field])
      }
      messageForShow = validationMessages
    } else if (data.code === responseAdvancedCodes.USER_API_GATEWAY_FORBIDDEN) {
      messageForShow = localize('Error.consumerExist')
    } else if (data.code === responseAdvancedCodes.USER_NOT_ACTIVE) {
      messageForShow = localize('Error.userCanNotAccess')
    } else if (data.code === responseAdvancedCodes.USER_CREDENTIALS_NOT_VALIDATION) {
      messageForShow = localize('Error.invalidEmailOrPassword')
    } else if (data.code === responseAdvancedCodes.USER_VERIFICATION_CODE_NOT_MATCH) {
      messageForShow = localize('Error.enteredCodeIsNotValid')
    } else if (data.code === responseAdvancedCodes.USER_PHONE_NOT_EXIST) {
      messageForShow = localize('Error.userDontHavePhoneFilled')
      // eslint-disable-next-line no-dupe-else-if
    } else if (data.code === responseAdvancedCodes.USER_API_GATEWAY_FORBIDDEN) {
      messageForShow = localize('Error.consumerExist')
    } else {
      messageForShow = !GlobalManager.isEmpty(data) && !GlobalManager.isEmpty(data.data) ? localize(data.data) : localize('Error.problemWithResource')
    }
  }
  return Promise.reject(messageForShow)
})

export default AxiosInstance
