import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import clsx from 'clsx'

import { PublicFooter, PublicHeader } from '../../layout-components'
import { ServerManager } from '../../services'

const styles = makeStyles(theme => ({
  content: {
    paddingTop: 150
  }
}))

const PublicLayout = props => {
  const { children, title } = props
  const classes = styles()
  const [externalLinks, setExternalLinks] = useState({})

  useEffect(() => {
    const fetchExternalLinks = async () => {
      const response = await ServerManager.getExternalLinks()
      if (response.success) {
        setExternalLinks(response.data.data)
      }
    }

    fetchExternalLinks()
  }, [])

  return (
    <Fragment>
      <CssBaseline/>
      <Container maxWidth="lg">
        <PublicHeader title={title} externalLinks={externalLinks}/>
        <div className={clsx('app-wrapper sm:d-w-full', classes.content)}>
          <div>{children}</div>
        </div>
      </Container>
      <PublicFooter externalLinks={externalLinks}/>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  title: state.PublicPage.title
})

export default connect(mapStateToProps, null)(PublicLayout)
