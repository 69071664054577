import React, { Fragment } from 'react'

import PagesError500Content from '../../theme-components/PagesError500/PagesError500Content/index'

export default function PagesError500 () {
  return (
    <Fragment>
      <PagesError500Content />
    </Fragment>
  )
}
