import React, { Fragment } from 'react'

import { Box } from '@material-ui/core'

export default function SidebarFooter () {
  return (
    <Fragment>
      <Box className="app-sidebar-footer-wrapper">
        <ul className="app-sidebar-footer">
        </ul>
      </Box>
    </Fragment>
  )
}
