import { GlobalManager } from './index'

class UIScreenManager {
  static getTokenFromLink (urlSufix, urlPrefix = null) {
    let location = window.location.pathname
    if (!GlobalManager.isEmpty(urlPrefix)) {
      location = location.replace(urlPrefix, '')
    }
    return location.replace('/users/public/' + urlSufix + '/', '')
  }
}

export default UIScreenManager
