import React from 'react'
import MetaTags from 'react-meta-tags'
import { localize } from 'services'
import metaImage from '../../assets/images/meta_image.jpg'

class MetaTag extends React.Component {
  render () {
    return (
      <div className="wrapper">
        <MetaTags>
          <meta name="description" content={localize('descriptionMetaTag')}/>
          <meta property="og:description" content={localize('descriptionMetaTag')} />
          <meta property="og:url" content={process.env.REACT_APP_BASE_URL + '/users/login'} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={localize('titleMetaTag')}/>
          <meta property="og:image" content={metaImage}/>
        </MetaTags>
      </div>
    )
  }
}

export default MetaTag
