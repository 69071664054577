import { SHOW_ERROR_NOTIFICATION, HIDE_ERROR_NOTIFICATION } from './types'

class NotificationManager {
  static showNotification (decoded) {
    return {
      type: SHOW_ERROR_NOTIFICATION,
      payload: decoded
    }
  }

  static hideNotification () {
    return { type: HIDE_ERROR_NOTIFICATION }
  }
}

export default NotificationManager
