import React, { Fragment } from 'react'
import clsx from 'clsx'
import { Facebook, LinkedIn, Instagram } from '@material-ui/icons'
import { Logo } from '../../layout-components'
import { getCurrentLanguage, localize } from '../../services'

import { AppBar, ButtonBase, Container, Divider, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core'

const styles = makeStyles(theme => ({
  topBar: {
    backgroundColor: '#2D2D2D',
    minHeight: 60,
    fontFamily: '\'DM Sans\', serif',
    fontSize: 16,
    boxShadow: 'none'
  },
  toolBarTitle: {
    backgroundColor: '#ffffff',
    minHeight: 84
  },
  topBarText: {
    fontFamily: '\'DM Sans\', serif',
    padding: '5px auto',
    fontSize: 16
  },
  socialIcons: {
    position: 'relative',
    marginLeft: 0,
    padding: theme.spacing(0, 2)
  },
  textContainer: {
    alignItems: 'center'
  },
  icon: {
    color: '#fff'
  },
  menuButton: {
    minWidth: '60px',
    paddingRight: 16,
    paddingLeft: 16
  },
  topBarDivider: {
    height: 18
  }
}))

const PublicHeader = props => {
  const { externalLinks } = props
  const classes = styles()
  const links = { ...externalLinks[getCurrentLanguage()] }

  return (
    <Fragment>
      <div>
        <AppBar className={clsx(classes.topBar, 'w-full')} color='secondary' position='absolute'>
          <Toolbar className={classes.topBar} justify='space-between'>
            <Container>
              <div className={clsx('d-flex flex-column flex-md-row justify-content-between')}>
                <div className={clsx('d-flex w-full col-md-6 justify-content-center justify-content-md-start')}>
                  <div className={clsx('d-flex d-flex-row', classes.textContainer)}>
                    <div className={clsx('d-flex d-flex-row')}>
                      <Typography className={clsx(classes.topBarText, 'font-size-16 text-nowrap text-gold')}>
                        <span>{localize('questions')}</span>
                        <a className='text-white' href={`tel:${(links.phoneNumber || localize('externalLinks.phoneNumber')).replace(/\s/g, '')}`}>{links.phoneNumber || localize('phoneNumberText')}</a>
                      </Typography>
                    </div>
                    <Divider orientation='vertical' className={clsx(classes.topBarDivider, 'bg-white mx-3')}/>
                    <div className={clsx('d-flex d-flex-row')}>
                      <Typography className={clsx(classes.topBarText, 'font-size-16 text-nowrap text-gold')}>
                        <span>{localize('approvalScreen.email') + ': '}</span>
                        <a className='text-white' href={`mailto:${links.email || localize('externalLinks.email')}`}>{links.email || localize('emailText')}</a>
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className={clsx('w-full col-md-6')}>
                  <div
                    className={clsx('d-flex w-full justify-content-center justify-content-md-end')}>
                    <IconButton href={links.facebook || localize('externalLinks.facebook')} target='_blank'>
                      <Facebook className={classes.icon} fontSize='small'/>
                    </IconButton>
                    <IconButton href={links.linkedin || localize('externalLinks.linkedin')} target='_blank'>
                      <LinkedIn className={classes.icon} fontSize='small'/>
                    </IconButton>
                    <IconButton href={links.instagram || localize('externalLinks.instagram')} target='_blank'>
                      <Instagram className={classes.icon} fontSize='small'/>
                    </IconButton>
                  </div>
                </div>
              </div>
            </Container>
          </Toolbar>
          <Toolbar className={clsx(classes.toolBarTitle, 'toolBarTitle')}>
            <Container className={clsx('d-flex d-flex-row justify-content-between')}>
              <div className={clsx('d-flex d-flex-col')}>
                <div className={clsx('d-flex d-flex-row mb-2')}>
                  <Logo externalLinks={links}/>
                </div>
              </div>
              <div className={clsx('d-flex d-flex-col')}>
                <div className={clsx('d-flex d-flex-row dark-text')}>
                  <ButtonBase className={clsx(classes.menuButton, 'font-size-16')}
                    href={links.contact || localize('externalLinks.contact')}
                    target='_blank'>{localize('contact')}</ButtonBase>
                  <ButtonBase className={clsx(classes.menuButton, 'font-size-16')}
                    href={links.faq || localize('externalLinks.faq')}
                    target='_blank'>{localize('faq')}</ButtonBase>
                </div>
              </div>
            </Container>
          </Toolbar>
        </AppBar>
      </div>
    </Fragment>
  )
}

export default PublicHeader
