import { SHOW_LOADING_BAR, HIDE_LOADING_BAR } from '../actions/types'

const initialState = {
  loadingBar: false,
  activeRequestsCounter: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case SHOW_LOADING_BAR: {
      const counter = state.activeRequestsCounter + 1
      return { ...state, loadingBar: true, activeRequestsCounter: counter }
    }
    case HIDE_LOADING_BAR: {
      if (state.activeRequestsCounter > 1) {
        const counter = state.activeRequestsCounter - 1
        return { ...state, activeRequestsCounter: counter }
      } else {
        return { ...initialState }
      }
    }
    default:
      return state
  }
}
