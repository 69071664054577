export default {
  TM_JWT_COOKIE_KEY: process.env.REACT_APP_TM_JWT_COOKIE_KEY,
  GK_BACKEND: process.env.REACT_APP_GK_BACKEND + '/administration',
  USER_BACKEND: process.env.REACT_APP_USER_BACKEND,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  USER_LOGIN_URL: process.env.REACT_APP_USER_LOGIN_URL,
  TRACKING_ID: process.env.REACT_APP_TRACKING_ID,
  GOOGLE_TAG_ID: process.env.REACT_APP_GOOGLE_TAG_ID,
  LOGO_NAME: process.env.REACT_APP_LOGO_NAME,
  INSTANCE_KEY: process.env.REACT_APP_INSTANCE_KEY ?? 'app-sl'
}
