import React, { useEffect } from 'react'
import ReactGA from 'react-ga'
import { connect, Provider, useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import configureStore from './config/configureStore'
import Routes from './Routes'
import ScrollToTop from './utils/ScrollToTop'
import './assets/base.scss'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Cookies } from 'react-cookie'
import { LinearProgress } from '@material-ui/core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { createBrowserHistory } from 'history'
import './assets/fonts/fonts.scss'
import MetaTag from './components/MetaTag/MetaTag'
import TagManager from 'react-gtm-module'
import {
  fab,
  faDiscord,
  faDribbble,
  faFacebook,
  faGithub,
  faGoogle,
  faHtml5,
  faInstagram,
  faPinterest,
  faReact,
  faSlack,
  faTwitter,
  faVuejs,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import {
  faAddressCard,
  faBell,
  faBuilding,
  faCalendarAlt,
  faChartBar,
  faCheckCircle,
  faClock,
  faCommentDots,
  faComments,
  faDotCircle,
  faEnvelope,
  faEye,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFileVideo,
  faFileWord,
  faFolder,
  faFolderOpen,
  faGem,
  faImages,
  faKeyboard,
  faLifeRing,
  faLightbulb,
  faMap,
  faObjectGroup,
  faQuestionCircle,
  far,
  faSquare,
  faThumbsUp,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUserCircle
} from '@fortawesome/free-regular-svg-icons'
import {
  faAlignCenter,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowUp,
  faAward,
  faBars,
  faBatteryEmpty,
  faBatteryFull,
  faBirthdayCake,
  faBomb,
  faBusAlt,
  faCameraRetro,
  faCarBattery,
  faCaretRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faCubes,
  faDownload,
  faEllipsisH,
  faEllipsisV,
  faExternalLinkAlt,
  faEyeDropper,
  faFilm,
  faHeart,
  faInfoCircle,
  faLemon,
  faLink,
  faMapMarkedAlt,
  faNetworkWired,
  faPager,
  faPlayCircle,
  faPlus,
  faPlusCircle,
  faPrint,
  faQuoteRight,
  faReply,
  fas,
  faSave,
  faSearch,
  faShapes,
  faShareSquare,
  faSignOutAlt,
  faSitemap,
  faSlidersH,
  faSmile,
  faStar,
  faStarHalfAlt,
  faSync,
  faTable,
  faTachometerAlt,
  faTimes,
  faUnlockAlt,
  faUpload
} from '@fortawesome/free-solid-svg-icons'
import config from 'config/config'
import AuthenticationManager from './store/actions/AuthenticationManager'
import { optionsRequest } from './services/RequestManager/RequestManager'
import { GOOGLE_ANALYTICS_USER_ID } from 'constants/Constants'
import { GlobalManager, initializeLanguage } from 'services'

library.add(
  far,
  faSquare,
  faLifeRing,
  faCheckCircle,
  faTimesCircle,
  faDotCircle,
  faThumbsUp,
  faComments,
  faFolderOpen,
  faTrashAlt,
  faFileImage,
  faFileArchive,
  faCommentDots,
  faFolder,
  faKeyboard,
  faCalendarAlt,
  faEnvelope,
  faAddressCard,
  faMap,
  faObjectGroup,
  faImages,
  faUser,
  faLightbulb,
  faGem,
  faClock,
  faUserCircle,
  faQuestionCircle,
  faBuilding,
  faBell,
  faFileExcel,
  faFileAudio,
  faFileVideo,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileAlt,
  faEye,
  faChartBar
)
library.add(
  fab,
  faFacebook,
  faTwitter,
  faVuejs,
  faReact,
  faHtml5,
  faGoogle,
  faInstagram,
  faPinterest,
  faYoutube,
  faDiscord,
  faSlack,
  faDribbble,
  faGithub
)
library.add(
  fas,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faSmile,
  faHeart,
  faBatteryEmpty,
  faBatteryFull,
  faChevronRight,
  faSitemap,
  faPrint,
  faMapMarkedAlt,
  faTachometerAlt,
  faAlignCenter,
  faExternalLinkAlt,
  faShareSquare,
  faInfoCircle,
  faSync,
  faQuoteRight,
  faStarHalfAlt,
  faShapes,
  faCarBattery,
  faTable,
  faCubes,
  faPager,
  faCameraRetro,
  faBomb,
  faNetworkWired,
  faBusAlt,
  faBirthdayCake,
  faEyeDropper,
  faUnlockAlt,
  faDownload,
  faAward,
  faPlayCircle,
  faReply,
  faUpload,
  faBars,
  faEllipsisV,
  faSave,
  faSlidersH,
  faCaretRight,
  faChevronUp,
  faPlus,
  faLemon,
  faChevronLeft,
  faTimes,
  faChevronDown,
  faFilm,
  faSearch,
  faEllipsisH,
  faCog,
  faArrowsAltH,
  faPlusCircle,
  faAngleRight,
  faAngleUp,
  faAngleLeft,
  faAngleDown,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faArrowLeft,
  faStar,
  faSignOutAlt,
  faLink
)

const store = configureStore()
const cookie = new Cookies()
const history = createBrowserHistory()

const App = () => {
  const dispatch = useDispatch()
  const jwtToken = cookie.get(config.TM_JWT_COOKIE_KEY)
  const app = useSelector(store => store.App)

  useEffect(() => {
    async function checkUserTokenValid () {
      if (jwtToken) {
        const response = await optionsRequest('/users/api/profile')
        if (response.success) {
          try {
            dispatch(AuthenticationManager.setAuthToken(jwtToken))
          } catch (error) {
            dispatch(AuthenticationManager.logoutUser)
          }
        } else {
          dispatch(AuthenticationManager.logoutUser)
        }
      }
    }

    checkUserTokenValid()
  }, [dispatch])

  useEffect(() => {
    initializeLanguage()
    initializeGoogleAnalytics()
  }, [])

  const initializeGoogleAnalytics = () => {
    if (!GlobalManager.isEmpty(config.GOOGLE_TAG_ID)) {
      TagManager.initialize({
        gtmId: config.GOOGLE_TAG_ID,
        events: {
          login: 'Log In',
          register: 'Sign Up'
        }
      })
    }

    if (!GlobalManager.isEmpty(config.TRACKING_ID)) {
      ReactGA.initialize(config.TRACKING_ID)
      ReactGA.set({ userId: GOOGLE_ANALYTICS_USER_ID })
      history.listen(location => {
        ReactGA.set({ page: location.pathname })
        ReactGA.pageview(location.pathname)
      })
    }
  }

  return (
    <BrowserRouter basename='/'>
      <MetaTag/>
      <CssBaseline/>
      <div className='main-loader'>
        {app.loadingBar && <LinearProgress variant='query' color='primary'/>}
      </div>
      <ScrollToTop>
        <Routes/>
      </ScrollToTop>
    </BrowserRouter>
  )
}

const ConnectedApp = connect()(App)

const Root = () => {
  return <Provider store={store}><ConnectedApp/></Provider>
}
export default Root
