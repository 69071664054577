import { getRequest, postRequest, putRequest } from '../RequestManager/RequestManager'
import { routes } from 'constants/Routes'

class ServerManager {
  static async userLogin (data) {
    return postRequest(routes.USER_LOGIN, data, true)
  }

  static async newSavingPlanDataApproved (data, token) {
    return postRequest(routes.NEW_SAVING_PLAN_CONFIRMED + token, data, true)
  }

  static async closeSavingPlanDat (data, token) {
    return postRequest(routes.CLOSE_SAVING_PLAN_CONFIRMED + token, data, true)
  }

  static async purchaseRequestConfirmedByCustomer (data, token) {
    return postRequest(routes.PURCHASE_CONFIRMED_BY_CUSTOMER + token, data)
  }

  static async oneTimeOrderCreationConfirmedByCustomer (data, token) {
    return postRequest(routes.CREATION_ONE_TIME_ORDER_CONFIRMED_BY_CUSTOMER + token, data)
  }

  static async getUserAndAccountData (token) {
    return getRequest(routes.USER_AND_ACCOUNT_DATA + '?token=' + token)
  }

  static async getPurchaseData (token) {
    return getRequest(routes.PURCHASE_DATA + '?token=' + token)
  }

  static async getOnetimeOrderData (token) {
    return getRequest(routes.ONE_TIME_ORDER_DATA + '?token=' + token)
  }

  static async getNotificationSettingsDataByEncryptedId (token) {
    return getRequest(routes.NOTIFICATION_SETTINGS_BY_ENCRYPTED_ID + '?token=' + token)
  }

  static async updateNotificationSettingsDataByEncryptedId (data) {
    return postRequest(routes.UPDATE_NOTIFICATION_SETTINGS + data.token, data, true)
  }

  static async removeToken (token) {
    return postRequest(routes.REMOVE_TOKEN + '?token=' + token)
  }

  static async getUserAndAccountDataForReviewingRevision (token) {
    return getRequest(routes.USER_AND_ACCOUNT_DATA_FOR_REVISION + '?token=' + token)
  }

  static async revisionConfirmation (token) {
    return postRequest(routes.REVISION_CONFIRMATION + '?token=' + token)
  }

  static async setupNewPassword (data) {
    return postRequest(routes.SETUP_NEW_PASSWORD, data)
  }

  static async verifyToken (data) {
    return postRequest(routes.VERIFY_TOKEN, data)
  }

  static async sendRequestFor2faCode (userId) {
    return getRequest(routes.GENERATE_2FA_CODE + userId)
  }

  static async verify2faCode (token, code) {
    return postRequest(routes.VERIFY_2FA_CODE, { token, code })
  }

  static async resetPassword (email) {
    return postRequest(routes.RESET_PASSWORD_REQUEST, email)
  }

  static async getExternalLinks () {
    return getRequest(routes.GET_EXTERNAL_LINKS)
  }

  static async registerNewCustomer (customerData) {
    return postRequest(routes.REGISTER_NEW_CUSTOMER, customerData)
  }

  static async resendConfirmationEmail (customerId) {
    return postRequest(routes.RESEND_CONFIRMATION_EMAIL + customerId)
  }

  static async changeTermsAndNewsStatus (data) {
    return postRequest(routes.CHANGE_TERMS_AND_NEWS, data)
  }

  static async customerDeactivationConfirmation (token) {
    return putRequest(routes.CUSTOMER_DEACTIVATION_CONFIRMATION + token)
  }

  static async getSettingsByKey (settingsKey) {
    return getRequest(routes.GET_SETTINGS_BY_KEY + settingsKey)
  }

  static async getSettings () {
    return getRequest(routes.GET_SETTINGS)
  }
}

export default ServerManager
