import { SET_PUBLIC_PAGE_TITLE, SET_REGISTER_FORM_INPUTS } from '../actions/types'

const initialState = {
  title: '',
  registerForm: { termsAndConditions: false, contactByEmail: true, contactByPhone: true, email: '', firstName: '', lastName: '', referralCode: '', isCompany: false }
}

export default function (state = initialState, action) {
  if (action.type === SET_PUBLIC_PAGE_TITLE) {
    return {
      ...state,
      title: action.payload.title
    }
  }
  if (action.type === SET_REGISTER_FORM_INPUTS) {
    return {
      ...state,
      registerForm: action.payload
    }
  }
  return state
}
