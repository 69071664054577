import React, { Fragment, lazy, Suspense } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import { ThemeProvider } from '@material-ui/styles'

import { ClimbingBoxLoader } from 'react-spinners'

import MuiTheme from './theme'
import { MinimalLayout, PublicLayout } from './layout-blueprints'
import PagesError404 from 'containers/ErrorPageScreens/ErrorPage404'
import PagesError505 from 'containers/ErrorPageScreens/ErrorPage505'
import PagesError500 from 'containers/ErrorPageScreens/ErrorPage500'

const LoginScreen = lazy(() => import('./containers/AuthenticationScreens/LoginScreen'))
const IdentifyScreen = lazy(() => import('./containers/AuthenticationScreens/IdentifyScreen'))
const Verification2FaScreen = lazy(() => import('./containers/AuthenticationScreens/2FaVerificationScreen'))
const SetupPasswordScreen = lazy(() => import('./containers/AuthenticationScreens/SetupPasswordScreen'))
const RevisionConfirmationScreen = lazy(() => import('./containers/RevisionConfirmationScreen/RevisionConfirmationScreen'))
const WithdrawalProductsConfirmationScreen = lazy(() => import('./containers/WithdrawAssetsConfirmationScreen'))
const ErrorTokenScreen = lazy(() => import('./containers/ErrorTokenScreen/ErrorTokenScreen'))
const SellingAssetsConfirmationScreen = lazy(() => import('./containers/SellingAssetsConfirmationScreen'))
const ManageSubscriptionsScreen = lazy(() => import('./containers/ManageSubscriptionsScreen/ManageSubscriptionsScreen'))
const Forbidden = lazy(() => import('./containers/ErrorPageScreens/Forbidden'))
const OneTimeOrderConfirmationScreen = lazy(() => import('./containers/OneTimeOrderConfirmationScreen'))
const TransferAssetsConfirmationScreen = lazy(() => import('./containers/TransferAssetsConfirmationScreen'))
const SavingPlanConfirmationScreen = lazy(() => import('./containers/SavingPlanConfirmationScreen'))
const SavingPlanCloseConfirmationScreen = lazy(() => import('./containers/SavingPlanCloseConfirmationScreen'))
const DeactivationConfirmationScreen = lazy(() => import('./containers/DeactivationConfirmationScreen'))

const Routes = () => {
  const location = useLocation()

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  }

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  }

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className='d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'>
          <div className='d-flex align-items-center flex-column px-4'>
            <ClimbingBoxLoader color={'#ffdf00'} loading={true}/>
          </div>

        </div>
      </Fragment>
    )
  }
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading/>}>
          <Switch>
            <Redirect exact from='/' to='/users/login'/>
            <Route path={[
              '/users/public/approve/:id',
              '/users/public/saving-plan-approval/:id',
              '/users/public/saving-plan-close-approval/:id',
              '/users/public/revision-confirmation/:id',
              '/users/public/transfer-assets-confirmation/:id',
              '/users/public/withdrawal-products/:id',
              '/users/public/selling-assets/:id',
              '/users/public/new-customer-confirmation/:id',
              '/users/public/manage-subscriptions/:id',
              '/users/error-token',
              '/users/public/approve-edit/:id',
              '/users/public/one-time-order-confirmation/:id',
              '/users/public/deactivation-confirmation/:id'
            ]}>
              <PublicLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path='/users/public/saving-plan-approval/:id' component={SavingPlanConfirmationScreen}/>
                    <Route path='/users/public/saving-plan-close-approval/:id' component={SavingPlanCloseConfirmationScreen}/>
                    <Route path='/users/public/revision-confirmation/:id' component={RevisionConfirmationScreen}/>
                    <Route path='/users/public/selling-assets/:id' component={SellingAssetsConfirmationScreen}/>
                    <Route path='/users/public/withdrawal-products/:id'
                           component={WithdrawalProductsConfirmationScreen}/>
                    <Route path='/users/public/transfer-assets-confirmation/:id'
                           component={TransferAssetsConfirmationScreen}/>
                    <Route path='/users/public/manage-subscriptions/:id' component={ManageSubscriptionsScreen}/>
                    <Route path='/users/error-token' component={ErrorTokenScreen}/>
                    <Route path='/users/public/one-time-order-confirmation/:id'
                           component={OneTimeOrderConfirmationScreen}/>
                    <Route path='/users/public/deactivation-confirmation/:id'
                           component={DeactivationConfirmationScreen}/>

                  </motion.div>
                </Switch>
              </PublicLayout>
            </Route>

            <Route
              path={[
                '/users/login',
                '/PagesRecoverPassword',
                '/PagesError404',
                '/PagesError500',
                '/PagesError505',
                '/users/error-token',
                '/users/public/withdrawal-products/:id',
                '/users/confirm/selling-assets/:id',
                '/users/error-token',
                '/users/public/setup-password/:token',
                '/users/verification',
                '/users/identify',
                '/users/public/forbidden'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial='initial'
                    animate='in'
                    exit='out'
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path='/users/login' component={LoginScreen}/>
                    <Route path='/users/verification' component={Verification2FaScreen}/>
                    <Route path='/users/identify' component={IdentifyScreen}/>
                    <Route path='/users/public/setup-password/:token' component={SetupPasswordScreen}/>
                    <Route path='/PagesError404' component={PagesError404}/>
                    <Route path='/PagesError500' component={PagesError500}/>
                    <Route path='/PagesError505' component={PagesError505}/>
                    <Route path='/users/public/withdrawal-products/:id'
                      component={WithdrawalProductsConfirmationScreen}/>
                    <Route path='/users/confirm/selling-assets/:id' component={SellingAssetsConfirmationScreen}/>
                    <Route path='/users/error-token' component={ErrorTokenScreen}/>
                    <Route path= '/users/public/forbidden' component={Forbidden}/>
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route path='*' exact={true} component={PagesError404}/>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  )
}

export default Routes
