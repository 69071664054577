import React, { Fragment } from 'react'
import Box from '@material-ui/core/Box'
import { get } from 'lodash'
import { Link } from '@material-ui/core'
import { ValoresLogo } from 'components/UI/SvgIcons'

const Logo = ({ externalLinks }) => {
  return (
    <Fragment>
      <Box
        className='header-logo-wrapper'
        title='Tim Valores'>
        <Link href={get(externalLinks, 'companyHomePage') ? `https://${get(externalLinks, 'companyHomePage')}` : '/'} className='header-logo-wrapper-link'>
          <ValoresLogo style={{ width: 71, height: 'auto' }} type="Primary" />
        </Link>
      </Box>
    </Fragment>
  )
}

export default Logo
