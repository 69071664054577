import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Box, Fab, Paper, Typography } from '@material-ui/core'

import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone'

import { connect } from 'react-redux'

import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import AddIcon from '@material-ui/icons/Add'
import GlobalManager from '../../services/GlobalManager'

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function PageTitle (props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription,
    addAction,
    children
  } = props
  return (
    <Fragment>
      <Paper
        square
        elevation={pageTitleShadow ? 6 : 2}
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground)}>
        <div>
          {pageTitleBreadcrumb && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize='small'/>}
              className='mb-4'
              maxItems={2}
              aria-label='breadcrumb'>
              <Link color='inherit' to='#' onClick={e => e.preventDefault()}>
                Home
              </Link>
              <Link color='inherit' to='#' onClick={e => e.preventDefault()}>
                Dashboards
              </Link>
              <Link color='inherit' to='#' onClick={e => e.preventDefault()}>
                Examples
              </Link>
              <Link color='inherit' to='#' onClick={e => e.preventDefault()}>
                Pages
              </Link>
              <Typography color='textPrimary'>{props.titleHeading}</Typography>
            </Breadcrumbs>
          )}

          <Box className='app-page-title--first'>
            {pageTitleIconBox && (
              <Paper
                elevation={2}
                className='app-page-title--iconbox d-70 d-flex align-items-center bg-secondary justify-content-center'>
                <DashboardTwoToneIcon/>
              </Paper>
            )}
            <div className='app-page-title--heading'>
              <h1>{props.titleHeading}</h1>
              {pageTitleDescription && (
                <div className='app-page-title--description'>
                  {props.titleDescription}
                </div>
              )}
            </div>
          </Box>
        </div>
        {!GlobalManager.isEmpty(addAction)
          ? <Fab color='secondary' aria-label='add' className='m-2 ml-2' onClick={addAction}>
            <AddIcon/>
          </Fab>
          : null}

        {children}
      </Paper>
    </Fragment>
  )
}

const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
})

export default connect(mapStateToProps)(PageTitle)
