import { HIDE_LOADING_BAR, SHOW_LOADING_BAR } from './types'

export default class AppManager {
  static showLoadingBar = () => dispatch => {
    dispatch({ type: SHOW_LOADING_BAR })
  }

  static hideLoadingBar = () => dispatch => {
    dispatch({ type: HIDE_LOADING_BAR })
  }
}
