import config from 'config/config'
import { SET_CURRENT_USER } from './types'
import { Cookies } from 'react-cookie'
import * as jwtDecode from 'jwt-decode'
import ServerManager from '../../services/ServerManager/ServerManager'
import AppManager from './AppManager'
import NotificationManager from './Notification'
import { rolesIds, twoFactoryVerification } from 'constants/Constants'
import { GlobalManager, localize } from 'services'
import TagManager from 'react-gtm-module'

class AuthenticationManager {
  static setCurrentUser (decoded) {
    return {
      type: SET_CURRENT_USER,
      payload: decoded
    }
  }

  static logoutUser = async (dispatch) => {
    this.setAuthToken(false)
    dispatch(this.setCurrentUser({}))
    const cookie = new Cookies()
    cookie.remove(config.TM_JWT_COOKIE_KEY, { path: '/' })
    window.location.href = config.USER_LOGIN_URL
  }

  static setAuthToken = token => dispatch => {
    const decoded = jwtDecode(token)
    if (token) {
      const cookie = new Cookies()
      dispatch(AuthenticationManager.setCurrentUser(decoded))
      cookie.set(config.TM_JWT_COOKIE_KEY, token, { path: '/', sameSite: 'strict' })
      this.loginGoogleEvent()
      if (!(window.location.href.indexOf('public') > -1)) {
        this.redirectByRole(decoded.role)
      }
    }
    dispatch(AppManager.hideLoadingBar())
  }

  static showNotificationOnLoginScreen = (message, title = localize('formErrors.loginTitle')) => async dispatch => {
    dispatch(NotificationManager.showNotification({
      title,
      message
    }))
  }

  static loginGoogleEvent = (userId) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        userId
      }
    })
  }

  static loginUser = (user, history) => async dispatch => {
    dispatch(AppManager.showLoadingBar())
    const response = await ServerManager.userLogin(user)
    if (response.success && !GlobalManager.isEmpty(response.data)) {
      const { token, advancedVerification, userId } = response.data
      localStorage.setItem('loginReferer', true)
      if (advancedVerification === twoFactoryVerification.ENABLED) {
        const response2 = await ServerManager.sendRequestFor2faCode(userId)
        if (response2.success) {
          dispatch(NotificationManager.hideNotification())
          history.push({
            pathname: '/users/verification',
            state: { token: response2.data.token, userId }
          })
        } else {
          dispatch(this.showNotificationOnLoginScreen(response2.err))
        }
      } else {
        dispatch(this.setAuthToken(token))
      }
    }
    if (!response.success) {
      dispatch(this.showNotificationOnLoginScreen(response.err))
    }
    dispatch(AppManager.hideLoadingBar())
  }

  static loginUserAfter2fa = (accessToken, verificationCode) => async dispatch => {
    dispatch(AppManager.showLoadingBar())
    const response = await ServerManager.verify2faCode(accessToken, verificationCode)
    if (response.success && !GlobalManager.isEmpty(response.data)) {
      const { token } = response.data
      dispatch(this.setAuthToken(token))
    }
    if (!response.success) {
      dispatch(this.showNotificationOnLoginScreen(response.err))
    }
    dispatch(AppManager.hideLoadingBar())
  }

  static redirectByRole = (roleId) => {
    const queryParams = new URLSearchParams(window.location.search)
    const id = queryParams.get('id')
    if (!GlobalManager.isEmpty(roleId)) {
      if (roleId === rolesIds.CUSTOMER) {
        localStorage.setItem('notificationId', id)
        window.location.href = '/home'
      } else {
        window.location.href = '/administration/home'
      }
    } else {
      localStorage.setItem('notificationId', id)
      window.location.href = '/users/login'
    }
  }
}

export default AuthenticationManager
